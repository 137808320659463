import { filter } from 'lodash';
import { useEffect, useState } from 'react';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from '@mui/material/styles';
import axios from 'axios';
import {
  Card,
  Alert,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  InputLabel,
  Box,
} from '@mui/material';
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { UserListHead, UserListToolbar, EditCreator } from '../AdminDash/sections';
import { useConfirm } from "material-ui-confirm";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import AdminDataRequest from '../../../APIRequest/AdminDataRequest';
import ToastMessage from 'helpers/ToastMessage';
import { getFormattedDate } from 'helpers/dateformat';
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email / Mobile', alignRight: false },
  { id: 'city', label: 'City ', alignRight: false },
  { id: 'insta', label: 'Insta', alignRight: false },
  { id: 'youtube', label: 'Youtube', alignRight: false },
  { id: 'facebook', label: 'Facebook', alignRight: false },
  { id: 'linked', label: 'linked', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'aoi', label: 'Area of Interest', alignRight: false },
  { id: 'mobile_verified', label: 'Mobile Verified', alignRight: false },
  { id: 'email_verified', label: 'Email Verified', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'edit', label: 'Edit' },
  { id: '', label: 'Action' },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AdminDash() {

  const theme = createTheme({
    palette: {
      background: {
        admin: 'white',
      },

    },
  });

  const [open, setOpen] = useState(null);

  const [Editopen, setEditOpen] = useState(null);
  const [rerun, setRerun] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [loader, setLoader] = useState(false);
  const [scrabloader, setScarbLoader] = useState(false);
  const [Wentwrong, setWentwrong] = useState(false);
  const [Resended, setResended] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userlist, setUserlist] = useState([]);
  const [popdata, setPopdata] = useState([]);
  const [Editdata, setEditdata] = useState();
  const [linkedscraber, setLinkedinScraber] = useState(
    { data: { full_name: '-', followers_count: '-', connections_count: '-' } }
  );
  const [instascraber, setInstaScraber] = useState(
    { response: { body: { data: { user: { edge_follow: { count: '-' }, edge_followed_by: { count: '-' } }, username: '-' } } } }
  );
  const [youtubescraber, setyoutubeScraber] = useState(
    { stats: { subscribersText: '-', videosText: '-' }, title: '' }
  );
  const [unnscrabed, setUnscrabed] = useState(false)
  const [scrabyoutube, setScrabyoutube] = useState(true)
  const [scrabInsta, setscrabInsta] = useState(true)
  const [scrablinked, setscrabLinked] = useState(true)
  const [InstaMediadata, setInstaMediadata] = useState('')
  const [instaEpercent, setInstaEpercent] = useState(0)

  const ranges = [
    {
      value: 'empty',
      label: 'No Range',
    },
    {
      value: '<5k',
      label: 'Less than 5000 (<5k)',
    },
    {
      value: '>5k<10k',
      label: '5000 - 10,000 (5k - 10k)',
    },
    {
      value: '>10k<20k',
      label: '10,000 - 20,000 (10k - 20k)',
    },
    {
      value: '>20k<50k',
      label: '20,000 - 50,000 (20k - 50k)',
    },
      {
      value: '>50k<100k',
      label: '50,000 - 100,000 (50k - 100k)',
    },
    
   {
      value: '>100k<500k',
      label: '100,000 - 500,000 (100k - 500k)',
    },
    {
      value: '>500k<1M',
      label: '500,000 - 10,00,000 (500k - 1M)',
    },
    {
      value: '1M+',
      label: 'Greator than 1M',
    },
   
  ];

  const [rangedata, setRangedata] = useState({

    youtube: 'empty',
    insta: 'empty',
    linkedin: 'empty',
    fb: 'empty',

  });

  //fecth users:

  useEffect(() => {

    const fetchData = async () => {
      try {

        const newData = await AdminDataRequest.getlist();

        if (newData.id === null || undefined) {
          setWentwrong(true);

        } else {
          setUserlist(newData)

          const timer = setTimeout(() => {

            //setVerification(true);
          }, 1000);
          return () => clearTimeout(timer);
        }

      }
      catch {
        setLoader(false)
        // setRequire(false);
        setWentwrong(true);

      }
    };
    fetchData();
  }, [rerun]);

  const confirm = useConfirm();
  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  function scrabdata() {

    setScarbLoader(true)
    if (isValidUrl(popdata.youtube_link)) { getYoutubedata() } else if (popdata.youtube_link != '') { console.log('Invalid Youtube URl'); setUnscrabed(true); setScrabyoutube(false); }
    if (isValidUrl(popdata.linkedin_link)) { getlinkedindata() } else if (popdata.linkedin_link != '') { setUnscrabed(true); setscrabLinked(false) }
    if (isValidUrl(popdata.insta_link) && popdata.insta_link.split('.com/')[1] != '' && popdata.insta_link.split('.com')[1] != '') { getInstadata() } else if (popdata.insta_link != '') { setUnscrabed(true); setscrabInsta(false) }
  }

  const getlinkedindata = async () => {

    if (popdata.linkedin_scrabed_data) {
      setUnscrabed(false)
      setscrabLinked(false)
      setLinkedinScraber(popdata.linkedin_scrabed_data)
      setScarbLoader(false)
    }
    else {

      const options = {
        method: 'GET',
        url: process.env.REACT_APP_SCRABER_LINKEDIN,
        params: {
          linkedin_url: popdata.linkedin_link
        },
        headers: {
          'X-RapidAPI-Key': process.env.REACT_APP_SCRABER_KEY,
          'X-RapidAPI-Host': process.env.REACT_APP_SCRABER_LINKEDIN_HOST,
        }
      };

      try {
        const response = await axios.request(options);

        setUnscrabed(false)
        setscrabLinked(false)
        setLinkedinScraber(response.data)
        AdminDataRequest.LinkedinScrabberUpdate(
          {
            linkedin_scrabed_data: response.data,
            linkedin_link: popdata.linkedin_link
          }
        )
        setScarbLoader(false)
      } catch (error) {
        setUnscrabed(true)
        setscrabLinked(false)
        console.error(error);
      }

    }
  };

  const getInstadata = async () => {
    if (popdata.insta_scrabed_data) {

      setUnscrabed(false)
      setscrabInsta(false)
      setInstaScraber(popdata.insta_scrabed_data)

      setInstaEpercent(parseFloat(popdata.instamedia_scrabed_data))
      setWentwrong(false);
      setScarbLoader(false)
    }
    else {

      const temp_name = popdata.insta_link.split('.com/')[1];
      const temp_name1 = temp_name.split('?')[0];

      const insta_username = temp_name1.replace('/', '')
      const options = {
        method: 'POST',
        url: process.env.REACT_APP_SCRABER_INSTA,
        headers: {
          'content-type': 'application/json',
          'X-RapidAPI-Key': process.env.REACT_APP_SCRABER_KEY,
          'X-RapidAPI-Host': process.env.REACT_APP_SCRABER_INSTA_HOST,
        },
        data: {
          username: insta_username
        }
      };
      try {
        const response = await axios.request(options);

        setUnscrabed(false)
        setscrabInsta(false)

        if (response.data.response.status_code === 200) {
          setInstaScraber(response.data)

          AdminDataRequest.InstaScrabberUpdate(
            {
              insta_scrabed_data: response.data,
              insta_link: popdata.insta_link
            }
          )
          ///////////// Calculate Engagemnet rate 
          insta_erate(response.data.response.body.data.user.id)

          setWentwrong(false);
        } else {
          setWentwrong(true);
        }

      } catch (error) {
        setUnscrabed(true)
        setscrabInsta(false)
        console.error(error);
      }
    }
  };

  const insta_erate = async (wid) => {

    const options = {
      method: 'POST',
      url: process.env.REACT_APP_SCRABER_INSTA_MEDIA,
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': process.env.REACT_APP_SCRABER_KEY,
        'X-RapidAPI-Host': process.env.REACT_APP_SCRABER_INSTA_HOST,
      },
      data: {
        id: parseInt(wid),
        count: 18,
        max_id: null
      }
    };
    try {
      const response = await axios.request(options);

      if (response.data.response.status_code === 200) {
        setInstaMediadata(response.data)

        setWentwrong(false);

        setScarbLoader(false)
      } else {
        setWentwrong(true);
      }

    } catch (error) {
      setUnscrabed(true)
      setscrabInsta(false)
      console.error(error);
    }
  };

  const getYoutubedata = async () => {

    if (popdata.youtube_scrabed_data) {

      setUnscrabed(false)
      setScrabyoutube(false)
      setyoutubeScraber(popdata.youtube_scrabed_data)
      setScarbLoader(false)

    }
    else {

      const options = {
        method: 'GET',
        url: process.env.REACT_APP_SCRABER_YOUTUBE,
        params: {
          id: popdata.youtube_link,
        },
        headers: {
          'X-RapidAPI-Key': process.env.REACT_APP_SCRABER_KEY,
          'X-RapidAPI-Host': process.env.REACT_APP_SCRABER_YOUTUBE_HOST,
        }
      };

      try {
        const response = await axios.request(options);

        setUnscrabed(false)
        setScrabyoutube(false)
        setyoutubeScraber(response.data)
        AdminDataRequest.YoutubeScrabberUpdate(
          {
            youtube_scrabed_data: response.data,
            youtube_link: popdata.youtube_link
          }
        )
        setScarbLoader(false)

      } catch (error) {
        setUnscrabed(true)
        setScrabyoutube(false)
        console.error(error);
      }
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userlist.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleapprove = (e) => {

    const id1 = e.target.value
    const url1 = new URL(process.env.REACT_APP_API_URL + '/' + id1);
    const param1 = {
      status: 'Approved',
      range_youtube: rangedata.youtube,
      range_insta: rangedata.insta,
      range_linkedin: rangedata.linkedin,
      range_fb: rangedata.fb,
    };
    const options1 = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param1),
    };

    const updatedata = async () => {
      try {
        const response1 = await fetch(url1, options1);
        const newData1 = await response1.json();

        if (newData1.id === null || undefined) {
          setWentwrong(true);

        } else {

          handlecreatorEmail(id1);


          setRerun(new Date().toLocaleTimeString())
          const timer = setTimeout(() => {

            //setVerification(true);
          }, 1000);
          return () => clearTimeout(timer);
        }

      }
      catch (error) {
        setLoader(false)
        //  setRequire(false);
        setWentwrong(true);

      }
    };
    updatedata();
    setOpen(false)
  }
  const handledecline = (e) => {

    const id2 = e.target.value;
    const url2 = new URL(process.env.REACT_APP_API_URL + '/' + id2);

    const param2 = {
      status: 'Declined'
    };
    const options2 = {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param2),
    };

    const updatedata2 = async () => {
      try {
        const response2 = await fetch(url2, options2);
        const newData2 = await response2.json();

        if (newData2.id === null || undefined) {
          setWentwrong(true);

        } else {
          setRerun(new Date().toLocaleTimeString())

          const timer = setTimeout(() => {

            //setVerification(true);
          }, 1000);
          return () => clearTimeout(timer);
        }

      }
      catch (error) {
        setLoader(false)
        //  setRequire(false);
        setWentwrong(true);

      }
    };
    updatedata2();
  }
  const handleOpen = (e) => {
    const popid = e.target.value
    setPopdata(userlist.find((item) => {
      return item.id == popid;
    }))

    setOpen(true);
  };

  const handleEditOpen = (e) => {
    const editdata = e.target.value
    setEditdata(userlist.find((item) => {
      return item.id == editdata;
    }))
    setEditOpen(true);
  };
  const handleClose = () => {
    setPopdata([])
    setRangedata({

      youtube: 'empty',
      insta: 'empty',
      linkedin: 'empty',
      fb: 'empty',

    })
    setLinkedinScraber(
      { data: { full_name: '-', followers_count: '-', connections_count: '-' } }
    );
    setInstaScraber(
      { response: { body: { data: { user: { edge_follow: { count: '-' }, edge_followed_by: { count: '-' } }, username: '-' } } } }
    );
    setyoutubeScraber(
      { stats: { subscribersText: '-', videosText: '-' }, title: '' }
    );
    setUnscrabed(false)
    setOpen(false);
    setscrabInsta(true)
    setscrabLinked(true)
    setScrabyoutube(true)
    setScarbLoader(false)
    setRerun(new Date().toLocaleTimeString())
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setRerun(new Date().toLocaleTimeString())
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleResendEmail = (wkemail) => {

    const param = {
      email: wkemail,
    };

    // (B) BUILD URL
    const url = new URL(process.env.REACT_APP_API_URL + "/sendmail");

    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param),
    };

    const sendMail = async () => {
      try {
        const response = await fetch(url, options);

        ToastMessage.successMessage('Email Sent Successfully');
      } catch (err) {
        console.log('Went wrong Catch' + err)
        ToastMessage.errorMessage('Error in Sending Email');
      }
    };
    sendMail()


  }


  const handlecreatorEmail = (wkid) => {

    const param = {
      user_id: wkid,
    };

    // (B) BUILD URL
    const url = new URL(process.env.REACT_APP_API_URL + "/sendmail_creator_details");

    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param),
    };

    const sendMail = async () => {
      try {
        const response = await fetch(url, options);

        ToastMessage.successMessage('Credentials Sent Successfully');
      } catch (err) {
        console.log('Went wrong Catch' + err)
        ToastMessage.errorMessage('Error in Sending Email');
      }
    };
    sendMail()


  }


  const handleChange = (e) => {

    setRangedata((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      }
    }
    );

  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userlist.length) : 0;

  const filteredUsers = applySortFilter(userlist, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    if (InstaMediadata != '') {
      const valuesd = InstaMediadata.response.body.items;
      let sum3 = 0;

      for (const element of valuesd) {
        const sum = element.like_count
        const sum2 = element.comment_count
        sum3 = sum3 + (sum + sum2)

      }

      const calculatedvalue = ((sum3 / 18) / parseInt(instascraber.response.body.data.user.edge_followed_by.count)) * 100
      setInstaEpercent(calculatedvalue)
      AdminDataRequest.InstaMediaScrabberUpdate(
        {
          instamedia_scrabed_data: calculatedvalue,
          insta_link: popdata.insta_link
        }
      )
    }
  }, [InstaMediadata])
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Container sx={{ maxWidth: '-webkit-fill-available !important' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Creators list
            </Typography>
          </Stack>

          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table sx={{ minWidth: 700 }}  >
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={userlist.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, avatarUrl, email, mobile, insta_link, facebook_link, city, youtube_link, linkedin_link, aoi, status, mobile_verified, email_verified, createdAt } = row;
                      const selectedUser = selected.indexOf(id) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                          </TableCell>

                          <TableCell id="name" component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Typography sx={{ maxWidth: 200, wordWrap: "break-word" }} variant="subtitle2" >
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left" sx={{
                            maxWidth: 200,
                            wordWrap: "break-word"
                          }}>{email}  {mobile}</TableCell>
                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>{city.join(', ')}</TableCell>
                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>{insta_link}</TableCell>

                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>{youtube_link}</TableCell>
                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>{facebook_link}</TableCell>
                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>{linkedin_link}</TableCell>
                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>   <Label color={(status === 'Declined' && 'error') || (status === 'Pending' && 'info') || 'success'}>{status}</Label></TableCell>

                          <TableCell align="left" sx={{ maxWidth: 200, wordWrap: "break-word" }}>{aoi}</TableCell>

                          <TableCell align="left" sx={{ maxWidth: 100, wordWrap: "break-word" }}>   <Label color={(mobile_verified === false && 'error') || (mobile_verified === true && 'success')}>{(mobile_verified === false && 'Not Yet') || (mobile_verified === true && 'Done')}</Label></TableCell>
                          <TableCell align="left" sx={{ maxWidth: 700, wordWrap: "break-word" }}>   <Label color={(email_verified === false && 'error') || (email_verified === true && 'success')}>{(email_verified === false && 'Not Yet') || (email_verified === true && 'Done')}</Label>
                            {email_verified === false && (
                              <Label

                                onClick={() => handleResendEmail(email)}
                                variant="contained"
                                color="white"
                                size="small"
                                sx={{ marginTop: 1, cursor: 'pointer', background: 'green', color: 'white' }}
                              >
                                Resend Email
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: 400, width: 300 }}>{getFormattedDate(createdAt)}</TableCell>
                          <TableCell align="left " sx={{ maxWidth: 300, wordWrap: "break-word" }}>

                            <Button value={id} onClick={handleEditOpen}>  <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} /> Edit  </Button>

                          </TableCell>

                          <TableCell align="left " sx={{ maxWidth: 300, wordWrap: "break-word" }}>
                            {mobile_verified &&

                              <>
                                <Button value={id} onClick={handleOpen}>  <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} /> Approve  </Button>

                                <Button value={id} onClick={handledecline}>    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2, color: 'error.main' }} /> Decline </Button>
                              </>

                            }
                          </TableCell>

                        </TableRow>

                      );

                    })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <Dialog fullWidth open={open} onClose={handleClose}>
              <DialogTitle>Select Range</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter the {popdata.name} 's social media influence range.

                </DialogContentText>
                <Button variant="contained" onClick={scrabdata}>Fetch Profile data</Button>
                {Wentwrong &&
                  <Stack sx={{ width: '100%', mt: 2 }} spacing={2}>
                    <Alert severity="error" >We could not able to fetch the data for the profiles!...

                    </Alert>

                  </Stack>
                }
                {unnscrabed &&
                  <Stack sx={{ width: '100%', mt: 2 }} spacing={2}>
                    <Alert severity="error" >Something Went Wrong while fetching data!...
                      Please Verify the profiles Manually for the below failed links:

                      {popdata.youtube_link != '' && <Alert severity={scrabyoutube ? 'success' : 'error'}>Youtube {scrabyoutube ? '' : '-  Invalid URL or  Failed to fetch the channel ID'}</Alert>}
                      {popdata.insta_link != '' && <Alert severity={scrabInsta ? 'success' : 'error'} >Instagram {scrabInsta ? '' : '-  Invalid URL or  Failed to fetch the Profile'}</Alert>}
                      {popdata.linkedin_link != '' && <Alert severity={scrablinked ? 'success' : 'error'} >Linked In {scrablinked ? '' : '-  Invalid URL or  Failed to fetch the Profile'}</Alert>}

                    </Alert>

                  </Stack>
                }

                {popdata.youtube_link != '' &&
                  <TextField disabled fullWidth
                    sx={{ mt: 2 }}
                    margin="dense"
                    label="Youtube"
                    type="text"
                    variant="standard"
                    value={popdata.youtube_link}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <YouTube />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                {scrabloader && popdata.youtube_link != '' &&
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="secondary" size={25} />
                  </Box>
                }
                {!scrabyoutube && !scrabloader && <InputLabel >Title: <b>{youtubescraber.title}</b>  |  Subscribers:  <b>{youtubescraber.stats.subscribersText}</b> | Videos: <b>{youtubescraber.stats.videosText}</b>  </InputLabel>}
                {popdata.youtube_link != '' &&
                  <TextField
                    required

                    select
                    fullWidth
                    margin="dense"
                    id="youtube"
                    name="youtube"
                    label="Youtube Subscribers"
                    type="text"
                    variant="standard"
                    value={rangedata.youtube}
                    onChange={handleChange}

                  >
                    {ranges.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                }
                <Box sx={{ background: green }}>

                  {popdata.insta_link != '' &&
                    <TextField disabled fullWidth sx={{ mt: 5 }}
                      margin="dense"
                      label="Insta"
                      type="text"
                      variant="standard"
                      value={popdata.insta_link}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Instagram />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  {scrabloader && popdata.insta_link != '' &&
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress color="secondary" size={25} />
                    </Box>
                  }
                  {!scrabInsta && !scrabloader && <InputLabel >Username: <b>{instascraber.response.body.data.user.username}</b>  |  Followers:  <b>{instascraber.response.body.data.user.edge_followed_by.count}</b> | Following: <b>{instascraber.response.body.data.user.edge_follow.count}</b> <br />Engagement Rate : {instaEpercent.toFixed(2)}%  </InputLabel>}
                  {popdata.insta_link != '' &&
                    <TextField

                      required
                      select
                      fullWidth
                      value={rangedata.insta}
                      onChange={handleChange}
                      margin="dense"
                      id="insta"
                      name="insta"
                      label="Insta Followers"
                      type="text"
                      variant="standard"

                    >
                      {ranges.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  }

                </Box>
                {popdata.linkedin_link != '' &&
                  <TextField disabled fullWidth sx={{ mt: 5 }}
                    margin="dense"
                    label="Linked in"
                    type="text"
                    variant="standard"
                    value={popdata.linkedin_link}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedIn />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                {scrabloader && popdata.linkedin_link != '' &&
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="secondary" size={25} />
                  </Box>
                }
                {!scrablinked && !scrabloader && <InputLabel >Full Name: <b>{linkedscraber.data.full_name}</b>  |  Followers:  <b>{linkedscraber.data.followers_count}</b> | Following: <b>{linkedscraber.data.connections_count}+</b>  </InputLabel>}
                {popdata.linkedin_link != '' &&

                  <TextField

                    required
                    select
                    fullWidth
                    value={rangedata.linkedin}
                    onChange={handleChange}
                    margin="dense"
                    id="linkedin"
                    name="linkedin"
                    label="Linkedin Followers"
                    type="text"
                    variant="standard"

                  >

                    {ranges.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}

                  </TextField>

                }

              </DialogContent>
              <DialogActions>
                <Button value={popdata.id} onClick={handleapprove.bind()}>Approve</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>

            <Dialog fullWidth open={Editopen} onClose={handleEditClose}>
              <DialogTitle>Edit Creator</DialogTitle>

              <EditCreator data={Editdata} onUpdate={handleEditClose} />

              <DialogActions>

                <Button onClick={handleEditClose}>Cancel</Button>
              </DialogActions>
            </Dialog>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={userlist.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default AdminDash;
