import { useState } from 'react';
// @mui

import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { useNavigate } from "react-router-dom";

import store from '../../../redux/store';
import { SetLogout } from "../../../redux/slice/AuthSlice";

import { RemoveUserDetails } from "../../../redux/slice/UserSlice";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate   = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
   
  };

  const handlelogout = () =>{
    sessionStorage.clear()
    store.dispatch(SetLogout());
    store.dispatch(RemoveUserDetails());

    localStorage.clear();

} 

return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'white',
            },
          }),
        }}
      >
          <Typography variant="h6" sx={{ m: 2, color: 'text.secondary' }} noWrap >
           Fluenz  Admin
          </Typography> <Avatar />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
       
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            background:'#fff',
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
           Fluenz Admin
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          admin@fluenzapp.com
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handlelogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
