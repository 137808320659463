import React, { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AdminDataRequest from "../../../APIRequest/AdminDataRequest";
import { Button, Card, CardMedia, Link, Tooltip, } from "@mui/material";
import { getFormattedDate } from "helpers/dateformat";
import Iconify from "components/iconify";
import Label from "components/label";

export default function App() {

  const [rerun, setRerun] = useState();
  const [loader, setLoader] = useState(false);
  const [wentwrong, setWentwrong] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [Open, setOpen] = useState(false)
  const fetchData = async () => {
    try {
      const result = await AdminDataRequest.getlinks();

      setTabledata(result); // Assuming result is an array of campaign objects
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [rerun]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    // Close modal
    setModalOpen(false);
  };

  const handleapprove = (id1) => {


    const url1 = new URL(process.env.REACT_APP_API_URL + '/link/' + id1);
    const param1 = {
      status: 'Active',

    };
    const options1 = {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param1),
    };

    const updatedata = async () => {
      try {
        const response1 = await fetch(url1, options1);
        const newData1 = await response1.json();
     
        if (newData1.id === null || undefined) {
          setWentwrong(true);
          setLoader(false)
        } else {
          setRerun(new Date().toLocaleTimeString())
         
        
        }

      }
      catch (error) {
        setLoader(false)
        //  setRequire(false);
        setWentwrong(true);

      }
    };
    updatedata();
    setOpen(false)
  }
  const handledecline = (id2) => {

    setLoader(true)
    const url2 = new URL(process.env.REACT_APP_API_URL + '/link/' + id2);

    const param2 = {
      status: 'Disabled'
    };
    const options2 = {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("AccessToken")
      },
      body: JSON.stringify(param2),
    };

    const updatedata2 = async () => {
      try {
        const response2 = await fetch(url2, options2);
        const newData2 = await response2.json();

        if (newData2.id === null || undefined) {
          setWentwrong(true);
          setLoader(false)
          setRerun(new Date().toLocaleTimeString())
        } else {
          setRerun(new Date().toLocaleTimeString())

        }

      }
      catch (error) {
        setLoader(false)
        //  setRequire(false);
        setWentwrong(true);

      }
    };
    updatedata2();
  }
  const handleConfirmation = () => {
    // Call update API with the id
    // Make sure to include the logic to call your update API with the id
    handleClose();
  };

  const theme = createTheme({
    palette: {
      background: {
        admin: "white"
      }
    }
  });


  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "Status  ",
        Cell: ({ row, renderedCellValue }) => {
      
          return (
           
            <Label color={(renderedCellValue === 'Disabled' && 'error') || (renderedCellValue === 'Active' && 'success') || 'success'}>
              {renderedCellValue}
            </Label>


          )
        }
      },

      {
        accessorKey: "creator_name",
        header: "Creator Name",
        Cell: ({ row, renderedCellValue }) => {

          return (
            row.original?.creator_data[0]?.name
          )
        }
      },
      {
        accessorKey: "creator_conatct",
        header: "Creator Conatact",
        Cell: ({ row, renderedCellValue }) => {

          return (
            row.original?.creator_data[0]?.email + '-' +
            row.original?.creator_data[0]?.mobile
          )
        }
      },

      {
        accessorKey: "name_campaign",
        header: "Campaign Name  ",
        Cell: ({ row, renderedCellValue }) => {

          return (
            row.original?.post_data[0]?.name_campaign
          )
        }
      },

      {
        accessorKey: "unique_link",
        header: "Unique Link",
        Cell: ({ row, renderedCellValue }) => {

          return (
            <Tooltip title={renderedCellValue}>
              <Link href='#'>Unique Link </Link>
            </Tooltip>

          )
        }
      },



      {
        accessorKey: "uploads",
        header: "uploads  ",
        Cell: ({ renderedCellValue, row }) => {
          const fileExtension = row.original.post_data[0]?.uploads
            ? row.original.post_data[0]?.uploads.split(".").pop().toLowerCase()
            : null;


          return (
            <Card>
              {fileExtension === "jpg" ||
                fileExtension === "jpeg" ||
                fileExtension === "png" ||
                fileExtension === "avif" ||
                fileExtension === "webp" ? (
                <CardMedia
                  component="img"
                  loading="lazy"
                  image={process.env.REACT_APP_API_URL + "/uploads/" + row.original.post_data[0]?.uploads}
                  alt={row.original.post_data[0]?.uploads}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
              ) : fileExtension === "mp4" ||
                fileExtension === "webm" ||
                fileExtension === "mkv" ||
                fileExtension === "mov" ||
                fileExtension === "mpeg4" ? (
                <CardMedia
                  component="video"

                  loop
                  muted
                  loading="lazy"
                  image={process.env.REACT_APP_API_URL + "/uploads/" + row.original.post_data[0]?.uploads}
                  alt={row.original.post_data[0]?.uploads}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
              ) : null}
            </Card>
          );
        }
      },


      {
        accessorKey: "click_count",
        header: "Click Count",
        Cell: ({ renderedCellValue, row }) => (

          renderedCellValue
        ),
      }
    ],
    []
  );

  const table = useMantineReactTable({
    data: tabledata,
    columns,
    enableColumnFilterModes: true,
    getRowId: (originalRow) => originalRow.id,
    enableRowActions: true,

    renderRowActions: ({ row, table }) => (
      <>
        <Button value={row.id} onClick={() => handleapprove(row.original._id)}>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} /> Activate{" "}
        </Button>

        <Button value={row.id} onClick={() => handledecline(row.original._id)}>

          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2, color: "error.main" }} /> Disable{" "}
        </Button>
      </>
    )
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <MantineReactTable table={table} />
    </ThemeProvider>
  );
}
